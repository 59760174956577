import React from "react"
import TextContent from '../../components/Text/TextContent'


const PostContent = ({text, animate}) => {

  /* let textTag = text.replace(/<p>/g, '<p data-sal="slide-up"data-sal-delay="300"data-sal-duration="1000"data-sal-easing="ease">');
  textTag = textTag.replace(/<h1>/g, '<h1 data-sal="slide-up"data-sal-delay="300"data-sal-duration="1000"data-sal-easing="ease">');
  textTag = textTag.replace(/<h2>/g, '<h2 data-sal="slide-up"data-sal-delay="300"data-sal-duration="1000"data-sal-easing="ease">');
  textTag = textTag.replace(/<h3>/g, '<h3 data-sal="slide-up"data-sal-delay="300"data-sal-duration="1000"data-sal-easing="ease">');
  textTag = textTag.replace(/<h4>/g, '<h4 data-sal="slide-up"data-sal-delay="300"data-sal-duration="1000"data-sal-easing="ease">');
  textTag = textTag.replace(/<h6>/g, '<h6 data-sal="slide-up"data-sal-delay="300"data-sal-duration="1000"data-sal-easing="ease">'); */

  let TextDiv;

  if(animate) {
    TextDiv = <TextContent data-sal="fade" data-sal-delay="300" data-sal-duration="1000" data-sal-easing="ease" className="textContent" dangerouslySetInnerHTML={{__html: text}}/>
  } else {
    TextDiv = <TextContent className="textContent" dangerouslySetInnerHTML={{__html: text}}/>
  }

  return (
      <div className="container px-3 text-left">
        <div className="row">
          <div  className=" col-12 px-4 px-md-3">
            {TextDiv}
          </div>
        </div>
      </div>
  )
}

export default PostContent
